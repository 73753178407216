import { useEffect, useState } from "react";
import AOS from "aos";
import { TypeAnimation } from "react-type-animation";
import "aos/dist/aos.css";
import logoApp from "./assets/images/logo-pepe.webp";
import imgPepeThink from "./assets/images/imgPepeThink.webp";
import imgTwitter from "./assets/images/imgTwitter.webp";
import imgTelegram from "./assets/images/imgTelegram.webp";
import imgLand from "./assets/images/imgLand.svg";
import imgPepeHatThink from "./assets/images/imgPepeHatThink.webp";
import imgH2B1 from "./assets/images/imgH2B1.png";
import imgH2B2 from "./assets/images/imgH2B2.png";
import imgH2B3 from "./assets/images/imgH2B3.png";
import imgH2B4 from "./assets/images/imgH2B4.png";
import imgPepeMeme from "./assets/images/imgPepeMeme.png";
import imgPepePee from "./assets/images/imgPepePee.png";
import iconMenu from "./assets/images/iconMenu.png";
import iconClose from "./assets/images/iconClose.png";
import iconUp from "./assets/images/iconUp.png";
import bybit from "./assets/images/ByBit_Icon.webp";
import bitget from "./assets/images/bitget-logo.webp";
import uniswap from "./assets/images/uniswap.webp";
import cmc from "./assets/images/cmc.webp";
import etherscan from "./assets/images/etherscan.webp";
import dextool from "./assets/images/dextools.webp";

import "./App.css";

function App() {
  const [isActive, setIsActive] = useState(false);
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 800,
      disable: "mobile",
    });
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 90) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="App overflow-hidden">
      <header
        className="page-header w-screen drop-shadow-md py-[44px] px-6
       bg-[#4C9641]">
        <div className="container mx-auto flex items-center justify-between">
          <img
            data-aos="fade-up"
            className="w-[150px] h-[48px] lg:w-[211px] lg:h-[68px] object-cover"
            src={logoApp}
            alt="logo"
          />
          <nav data-aos="fade-up" className="hidden lg:block">
            <ul className="flex text-lg">
              <li className="p-[10px] m-1 text-white">
                <a href="/">home</a>
              </li>
              <li className="p-[10px] m-1 text-white">
                <a href="/">about</a>
              </li>
              <li className="p-[10px] m-1 text-white">
                <a href="/">how to buy</a>
              </li>
              <li className="p-[10px] m-1 text-white">
                <a href="/">tokenomics</a>
              </li>
              <li className="p-[10px] m-1 text-white">
                <a href="/">roadmap</a>
              </li>
            </ul>
          </nav>
          <button
            data-aos="flip-left"
            className="text-white text-[10px] rounded-[60px] border border-white w-[147px] h-[45px] hidden lg:block">
            buy now
          </button>
          <button onClick={() => setIsActive(true)} className="block lg:hidden">
            <img src={iconMenu} alt="menu" />
          </button>
        </div>
      </header>
      <main className="bg-[#4C9541]">
        <div className="relative">
          <section className="section-spepe bg-contain min-h-screen bg-no-repeat opacity-20"></section>
          <div
            className="absolute left-0 right-0 top-0 container mx-auto px-6 lg:px-[128px] py-[60px]
           flex flex-col lg:flex-row items-center">
            <div className="content-left text-center">
              <div data-aos="fade-right" className="lg:text-left">
                <h2 className="text-[60px] text-white font-black uppercase">
                  Pepe PoW
                </h2>
                <p className="text-[18px] lg:text-[28px] text-white leading-10 mb-12">
                  Experience the power of Pepe like never before with Pepe PoW !
                </p>
              </div>
              <div className="flex gap-5 justify-center lg:justify-start">
                <a
                  data-aos="zoom-in"
                  href="https://twitter.com/pepepoweth"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={imgTwitter} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="https://t.me/pepepoweth"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={imgTelegram} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={dextool} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={etherscan} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={cmc} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={uniswap} alt="" />
                </a>
              </div>
              <div className="grid grid-cols-2 gap-3 mt-12  lg:gap-5">
                <a
                  data-aos="zoom-in"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex gap-5 text-white items-center py-1 text-lg px-10 border-[1px] border-white rounded-full lg:text-2xl">
                  <span>bitget</span>
                  <img className="w-8" src={bitget} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex gap-5 text-white items-center py-1 text-lg px-10 border-[1px] border-white rounded-full lg:text-2xl">
                  <span>bybit</span>
                  <img className="w-10 mr-5" src={bybit} alt="" />
                </a>
                <span className="text-red-600 text-xl">Soon</span>
                <span className="text-red-600 text-xl">Soon</span>
              </div>
            </div>
            <div data-aos="fade-left" className="content-right w-full h-full">
              <img
                className="w-[280px] mx-auto lg:w-[476px] overflow-hidden object-cover bg-cover"
                src={imgPepeThink}
                alt=""
              />
            </div>
          </div>
        </div>
        <section className="section-about">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-6 lg:px-[120px] lg:gap-[60px] py-[80px] flex flex-col-reverse lg:flex-row lg:items-center">
            <div data-aos="fade-right" className="content-left flex-shrink-0">
              <img
                className="w-[280px] lg:w-[400px] mx-auto"
                src={imgPepeHatThink}
                alt=""
              />
            </div>
            <div className="content-right break-words">
              <h2 className="text-[27px] uppercase lg:text-[56px] pb-[40px] font-black text-white text-left lg:text-center drop-shadow-[0_2px_2px_rgba(0,0,0,1)]">
                about
              </h2>
              <p
                data-aos="fade-left"
                data-aos-duration="400"
                className="text-[18px] break-words lg:text-[23px] text-white ">
                Pepe PoW is tired of watching everyone play hot potato with the
                endless derivative ShibaCumGMElonKishuTurboAssFlokiMoon Inu
                coins. The Inu’s have had their day. It’s time for the most
                recognizable meme in the world to take his reign as king of the
                memes.
              </p>
              <p
                data-aos="fade-left"
                data-aos-duration="700"
                className="text-[18px] lg:text-[23px] pt-7 text-white">
                Pepe PoW is here to make memecoins great again. Launched stealth
                with no presale, zero taxes, LP burnt and contract renounced,
                $PEPEW is a coin for the people, forever. Fueled by pure memetic
                power, let $PEPEW show you the way.
              </p>
            </div>
          </div>
        </section>
        <section className="section-how-to-buy">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-[50px] py-[60px]">
            <h2 className="text-[27px] uppercase font-black lg:text-[56px] pb-[40px] text-white text-center drop-shadow-[0_2px_2px_rgba(0,0,0,1)]">
              how to buy
            </h2>
            <div
              className="item bg-[#264A20] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2">
              <div className="w-[221px] shrink-0">
                <img className="w-[60%] mx-auto" src={imgH2B1} alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] font-bold lg:text-[23px] pb-4">
                  Create a wallet
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    // Same substring at the start will only be typed out once, initially
                    "download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io.",
                  ]}
                  wrapper="span"
                  speed={50}
                  className="text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
            <div
              className="item bg-[#264A20] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2">
              <div className="w-[221px] shrink-0">
                <img className="w-[60%] mx-auto" src={imgH2B2} alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] lg:text-[23px] font-bold pb-4">
                  Get Some ETH
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    "have ETH in your wallet to switch to PEPEW. If you don’t have any ETH, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet.",
                  ]}
                  wrapper="span"
                  speed={50}
                  className="text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
            <div
              className="item bg-[#264A20] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] py-[32px] text-[23px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2">
              <div className="w-[221px] shrink-0">
                <img className="w-[60%] mx-auto" src={imgH2B3} alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] lg:text-[23px] font-bold pb-4">
                  Go to Uniswap
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    "connect to Uniswap. Go to app.uniswap.org in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the PEPEW token address into Uniswap, select PEPEW, and confirm. When Metamask prompts you for a wallet signature, sign.",
                  ]}
                  wrapper="span"
                  speed={50}
                  className="text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
            <div
              className="item bg-[#264A20] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2">
              <div className="w-[221px] shrink-0">
                <img src={imgH2B4} className="mx-auto" alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] lg:text-[23px] font-bold pb-4">
                  Switch ETH for PEPEW
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    "switch ETH for PEPEW. We have ZERO taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.",
                  ]}
                  wrapper="span"
                  speed={50}
                  className="text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section-tokenomics">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-[50px] py-[60px]">
            <h2 className="font-black text-[27px] lg:text-[56px] pb-[40px] text-white text-center drop-shadow-[0_2px_2px_rgba(0,0,0,1)]">
              PEPEW
            </h2>
            <div className="content flex flex-col lg:flex-row gap-[60px] justify-between lg:px-[90px] text-white">
              <div>
                <p
                  data-aos="zoom-in"
                  className=" leading-8 text-center text-xl">
                  Total supply
                  <br />
                  420,690,000,000,000
                </p>
                <div
                  className="item bg-[#264A20] mt-[38px] flex px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2">
                  <TypeAnimation
                    cursor={false}
                    sequence={[
                      "NO PRESALE - NO TEAM TOKENS - NO TAX - NO BULLSHIT \n\n Pepe PoW, the ultimate meme ruler! Tired of the ShibaCumGMElonKishuTurboAssFlokiMoon Inu craze, Pepe PoW is here to reclaim the throne of memes. With a stealth launch, zero taxes, and a renounced contract, $PEPEW is the people's coin for eternity. Say goodbye to boring memecoins and join the revolution of pure memetic power! Let Pepe PoWguide you towards greatness and make memecoins fun again. Long live the meme king!",
                    ]}
                    speed={20}
                    className="text-sm lg:text-[19px] whitespace-pre-line w-full break-words"
                  />
                </div>
              </div>
              <div data-aos="fade-left" className="flex flex-col items-center">
                <p className="text-center">
                  CEX
                  <br />
                  6.9
                </p>
                <div className="w-[250px] h-[250px] lg:w-[433px] lg:h-[433px] my-6 object-cover shrink-0">
                  <img src={imgPepeMeme} alt="" />
                </div>
                <p className="text-center">
                  Liquidity Pool
                  <br />
                  93.1%
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-roadmap">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-[50px] py-[60px]">
            <h2 className="font-black uppercase text-[27px] lg:text-[56px] text-white text-center drop-shadow-[0_2px_2px_rgba(0,0,0,1)]">
              ROADMAP
            </h2>
            <div className="flex flex-col lg:flex-row justify-between items-center">
              <div
                data-aos="fade-right"
                className="w-[293px] h-[297px] my-6 object-cover shrink-0">
                <img src={imgPepePee} alt="" />
              </div>
              <div
                data-aos="flip-left"
                className="item bg-[#264A20] mt-[38px] flex px-1 text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2 h-fit">
                <p className="text-xl text-center py-5 px-12">
                  Ready for a Pepe that actually works? Look no further than
                  Pepe PoW!
                </p>
              </div>
              <div
                data-aos="fade-left"
                className="w-[293px] h-[297px] my-6 object-cover shrink-0">
                <img src={imgPepeThink} alt="" />
              </div>
            </div>
            <p className="text-[22px] leading-7 text-white text-center">
              All jokes aside, here is a rough sketch of PEPEW path ahead. We
              dont wan’t to give everything away on day 1, Expect surprises
              along the way ;)
            </p>
            <div className="flex flex-col lg:flex-row justify-between gap-12">
              <div
                className="item lg:w-1/3 bg-[#264A20] mt-[38px] flex px-6 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2 h-fit">
                <p className="text-[19px] ">
                  <h2 className=" text-[23px] font-bold text-center pb-6">
                    Phase 1
                  </h2>
                  <ul className="leading-9 break-words text-[16px] lg:text-[20px]">
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Launch Pepe PoW
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Socials
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Website Live
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      CoinGecko/Coinmarketcap Listings
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      690+ Holders
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Get $PEPEW Trending on twitter
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Community Engagement
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      CEX Listing
                    </li>
                  </ul>
                </p>
              </div>
              <div
                className="item lg:w-1/3 bg-[#264A20] mt-[38px] flex px-6 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2 h-fit">
                <p className="text-[19px] ">
                  <h2 className=" text-[23px] text-center font-bold pb-6">
                    Phase 2
                  </h2>
                  <ul className="leading-9 break-words text-[20px]">
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      CMC Listing
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      CG Listing
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Partnerships
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Buyback & Burn
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      10,000+ Holders
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Major CEX Listing
                    </li>
                  </ul>
                </p>
              </div>
              <div
                className="item lg:w-1/3 bg-[#264A20] mt-[38px] flex px-6 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2 h-fit">
                <p className="text-[19px]">
                  <h2 className=" text-[23px] text-center font-bold pb-6">
                    Phase 3
                  </h2>
                  <ul className="leading-9 break-words text-[20px]">
                    <li className=" before:mr-[4px] before:-ml-3">...</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-roadmap">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-[50px] py-[60px]">
            <p className="text-lg leading-7 text-white text-center">
              Pepe PoW, the ultimate turbocharged Pepe experience! This isn't
              your average Pepe; it's a Pepe that works wonders! Get ready to be
              blown away by the exhilaration of proof-of-work power. Pepe PoW
              takes fun to a whole new level, delivering a knockout punch of
              excitement. Bid farewell to the ordinary and embrace the
              powerhouse of Pepe PoW! With a team of seasoned Devs and Crypto
              analysts, the adventure is about to begin. Brace yourself for the
              ride of a lifetime! Get your hands on Pepe PoW now!
            </p>
          </div>
        </section>
      </main>
      <footer className="bg-[#3A963D]">
        <div className="container mx-auto">
          <p className="py-[17px] text-center text-[13px]">
            © 2023 by PEPEW. All rights reserved!
          </p>
        </div>
      </footer>
      {isShow && (
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="lg:hidden w-[44px] h-[44px] flex items-center justify-center
           bg-white border border-black rounded-[50%] fixed bottom-11 right-11">
          <img className="w-[28px]" src={iconUp} alt="" />
        </button>
      )}
      {isActive && (
        <div className="menu-sp animate-fadeInDown w-full h-screen bg-[#3A963D] fixed left-0 right-0 top-0 bottom-0 overflow-hidden">
          <div className="flex justify-end">
            <button className="my-6 mr-6" onClick={() => setIsActive(false)}>
              <img className="w-[25px] h-[25px]" src={iconClose} alt="" />
            </button>
          </div>
          <nav>
            <ul className="text-[16px] text-center">
              <li className="p-[10px] mb-6 text-white">
                <a href="/">home</a>
              </li>
              <li className="p-[10px] mb-6 text-white">
                <a href="/">about</a>
              </li>
              <li className="p-[10px] mb-6 text-white">
                <a href="/">how to buy</a>
              </li>
              <li className="p-[10px] mb-6 text-white">
                <a href="/">tokenomics</a>
              </li>
              <li className="p-[10px] mb-6 text-white">
                <a href="/">roadmap</a>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
}

export default App;
